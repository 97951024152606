<template>
  <div class="page-content">
    <div class="header">
      <h3>Dossiers ITI+</h3>
      <b-button size="sm" variant="success" v-b-modal.addDevisModal>
        <font-awesome-icon class="" icon="plus" />
        Créer un dossier
      </b-button>
    </div>
    <hr />
    <div class="content-devis">
      <div class="search">
        <span class="searchIcon" title="search">
          <font-awesome-icon icon="search"
        /></span>
        <input
          v-debounce:400="filter"
          class="input-search"
          type="text"
          placeholder="Rechercher un dossier "
          v-model="full_name_code"
        />
        <div v-if="!initLoading && getDevisLoadingIti" class="sortloading">
          Chargement en cours...
        </div>
      </div>
      <div v-if="initLoading" class="init-loading three-dots-loading">
        Chargement en cours
      </div>
      <div v-else class="content-tab-devis">
        <div>
          <b-table-simple
            small
            show-empty
            id="my-table2"
            :items="devisIti"
            :current-page="page"
            :per-page="0"
            no-local-sorting
            :sort-by.sync="sort_by"
            @sort-changed="sort"
            :sort-direction="sortDirection"
            :sticky-header="true"
            empty-text="Il n'y a aucun enregistrement à afficher"
            empty-filtered-text="Aucun enregistrement ne correspond à votre demande"
          >
            <b-thead>
              <b-tr>
                <b-th colspan="2">Code</b-th>
                <b-th
                  class="sans_border"
                  colspan="1"
                  @click="sort('first_name')"
                >
                  <font-awesome-icon
                    v-if="this.sortDirection === 'asc'"
                    class
                    icon="caret-down"
                  />
                  <font-awesome-icon
                    v-if="this.sortDirection === 'desc'"
                    icon="caret-up"
                  />

                  <span> Client </span></b-th
                >
                <b-th class="sans_border" colspan="1">Créé par </b-th>
                <b-th colspan="2">Statut signature</b-th>
                <b-th colspan="3">Télécharger</b-th>
                <b-th colspan="2">Signature<br />électronique </b-th>
              </b-tr>
              <b-tr>
                <b-th title="Devis">Devis</b-th>
                <b-th title="Bon de commande">BDC</b-th>
                <b-th></b-th>
                <b-th></b-th>
                <b-th title="Devis">Devis</b-th>
                <b-th title="Bon de commande">BDC</b-th>
                <b-th title="Cadre de contribution">CC</b-th>
                <b-th title="Devis">Devis</b-th>
                <b-th title="Bon de commande">BDC</b-th>
                <b-th title="Devis">Devis</b-th>
                <b-th title="Bon de commande">BDC</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="item in devisIti" :key="item.id">
                <b-th rowspan="1"
                  ><div class="font">{{ item.code }}</div></b-th
                >
                <b-th
                  ><div class="font">{{ item.code_bc }}</div></b-th
                >
                <b-th rowspan="1"
                  ><div class="font-name">{{ item.full_name }}</div></b-th
                >
                <b-th
                  ><div class="font">
                    {{ item.createdBy ? item.createdBy.data.full_name : '-' }}
                  </div></b-th
                >
                <b-th>
                  <p class="check" v-if="item.signed == 'done'">
                    <font-awesome-icon icon="check" />
                  </p>
                  <div v-if="item.signed == 'pending'" class="font">
                    En cours
                  </div>
                  <p v-if="item.signed == 'none'"></p>
                </b-th>
                <b-th>
                  <p class="check" v-if="item.signed_bc == 'done'">
                    <font-awesome-icon icon="check" />
                  </p>
                  <div v-if="item.signed_bc == 'pending'" class="font">
                    En cours
                  </div>
                  <p v-if="item.signed_bc == 'none'"></p>
                </b-th>
                <b-th
                  ><b-button
                    size="sm"
                    title="Télécharger un CC "
                    variant="outline-secondary"
                    @click="hundleDownloadClick(item, 'cc')"
                  >
                    <font-awesome-icon class="" icon="download" title="" />
                  </b-button>
                </b-th>
                <b-th
                  ><b-button
                    size="sm"
                    title="Télécharger un devis "
                    variant="outline-secondary"
                    @click="hundleDownloadClick(item, 'devis')"
                  >
                    <font-awesome-icon
                      class=""
                      icon="download"
                      title=""
                    /> </b-button
                ></b-th>
                <b-th>
                  <b-button
                    size="sm"
                    title="Télécharger un bon de commande "
                    variant="outline-secondary"
                    @click="hundleDownloadClick(item, 'bc')"
                  >
                    <font-awesome-icon class="" icon="download" /> </b-button
                ></b-th>
                <b-th>
                  <b-button
                    size="sm"
                    title="Signature électronique de devis "
                    variant="outline-secondary"
                    @click.prevent="hundleConfirmeSignedClick(item, 'devis')"
                  >
                    <font-awesome-icon
                      class=""
                      icon="file-signature"
                    /> </b-button
                ></b-th>
                <b-th>
                  <b-button
                    size="sm"
                    title="Signature électronique de bon de commande  "
                    variant="outline-secondary"
                    @click.prevent="hundleConfirmeSignedClick(item, 'bc')"
                  >
                    <font-awesome-icon
                      class=""
                      icon="file-signature"
                    /> </b-button
                ></b-th>
                <b-th>
                  <div class="actions">
                    <div>
                      <a
                        href="#"
                        class="font"
                        title="Modifier un dossier"
                        @click.prevent="handleClickUpdate(item)"
                      >
                        Modifier
                      </a>
                    </div>
                    <div v-if="currentUser.isSuper">
                      <a
                        href="#"
                        class="font"
                        title="Supprimer un dossier"
                        @click.prevent="hundleDeleteClick(item)"
                      >
                        Supprimer
                      </a>
                    </div>
                  </div>
                </b-th>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
        <div class="pagination">
          <div v-if="getDevisLoadingIti" class="three-dots-loading loading">
            Chargement en cours
          </div>
          <b-pagination
            v-model="page"
            :total-rows="devisCountIti"
            :per-page="per_page"
            align="right"
            size="sm"
            prev-text="Précédent"
            next-text="Suivant"
            aria-controls="my-table2"
            @change="pagination"
          ></b-pagination>
        </div>
      </div>
      <!-- Add Devis Modal  -->
      <b-modal
        scrollable
        id="addDevisModal"
        title="Créer un dossier"
        no-close-on-backdrop
        :hide-footer="true"
        ref="addDevisModal"
        size="lg"
        @show="resetModal"
        @hidden="resetModal()"
      >
        <form ref="newUserForm" @submit.prevent="handleSubmit">
          <b-row>
            <b-col cols="6">
              <div class="firstname">
                <b-form-group label="Prénom" label-for="firstname-input">
                  <b-form-input
                    id="firstname-input"
                    v-model="devisToAdd.first_name"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Nom" label-for="lastname-input">
                <b-form-input
                  id="lastname-input"
                  v-model="devisToAdd.last_name"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="6">
              <b-form-group
                label="E-mail de client "
                label-for="email-client-input"
              >
                <b-form-input
                  v-model="devisToAdd.email_client"
                  id="email-client-input"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group label="Téléphone " label-for="phone-input">
                <vue-tel-input
                  v-model="devisToAdd.phone"
                  @onInput="phoneInternational"
                  v-bind="bindProps"
                ></vue-tel-input>
                <div v-if="message_error" class=" message-phone-error">
                  {{ message_error }}
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="5">
              <b-form-group label="Adresse " label-for="Address-input">
                <b-form-input
                  v-model="devisToAdd.address"
                  id="Address-input"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4" lg="2">
              <b-form-group label="Code Postal" label-for="zipCode-input">
                <b-form-input
                  v-model="devisToAdd.zipCode"
                  id="zipCode-input"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="8" lg="5">
              <b-form-group label="Ville" label-for="ville-input">
                <b-form-input
                  v-model="devisToAdd.town"
                  id="ville-input"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Surface (m²)" label-for="Surface-input">
                <b-form-input
                  id="Surface-input"
                  v-model="devisToAdd.surface"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-group label="Précarité">
                <b-form-radio-group
                  v-model="devisToAdd.precariousness"
                  :options="precarite"
                  stacked
                ></b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Type de chauffage">
                <b-form-radio-group
                  v-model="devisToAdd.type_chauffage"
                  :options="type_chauffage"
                  stacked
                ></b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Zone">
                <b-form-radio-group
                  v-model="devisToAdd.zone"
                  :options="zone"
                  stacked
                ></b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group label="E-mail d'agent" label-for="email-agent-input">
            <b-form-input
              v-model="devisToAdd.email_agent"
              id="email-agent-input"
            ></b-form-input>
            <strong class="type"
              >* si vous souhaitez utiliser un autre email à la place de l'email
              de votre compte pour recevoir les notifications de signature des
              documents, merci de renseigner ce champ.
            </strong>
          </b-form-group>
          <div class="message">
            <div v-if="loading" class="three-dots-loading loading">
              Chargement en cours
            </div>
            <div v-if="error" class="error">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>
          </div>
          <div class="form-actions">
            <b-button
              @click="hideModal('addDevisModal')"
              variant="outline-secondary"
              >Fermer</b-button
            >
            <b-button variant="success" type="submit">
              <span> Enregistrer </span></b-button
            >
          </div>
        </form>
      </b-modal>
      <!-- END Add Devis Modal  -->
      <!-- update Devis Modal  -->
      <b-modal
        scrollable
        id="updateDevisModal"
        title="Modifier un dossier"
        no-close-on-backdrop
        :hide-footer="true"
        size="lg"
        ref="updateDevisModal"
        @hidden="resetModal()"
      >
        <form v-if="devisToUpdate" @submit.prevent="handleSubmitUpdateDevis">
          <b-row>
            <b-col cols="6">
              <div class="firstname">
                <b-form-group label="Prénom" label-for="firstname-input">
                  <b-form-input
                    id="firstname-input"
                    v-model="devisToUpdate.first_name"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="6">
              <div class="lastname">
                <b-form-group label="Nom" label-for="lastname-input">
                  <b-form-input
                    id="lastname-input"
                    v-model="devisToUpdate.last_name"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="6">
              <b-form-group
                label="E-mail de client "
                label-for="email-client-input"
              >
                <b-form-input
                  v-model="devisToUpdate.email_client"
                  id="email-client-input"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group label="Téléphone " label-for="phone-input">
                <vue-tel-input
                  v-model="devisToUpdate.phone"
                  @onInput="phoneInternational"
                  v-bind="bindProps"
                ></vue-tel-input>
                <div v-if="message_error" class=" message-phone-error">
                  {{ message_error }}
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="5">
              <b-form-group label="Adresse " label-for="Address-input">
                <b-form-input
                  v-model="devisToUpdate.address"
                  id="Address-input"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4" lg="2">
              <b-form-group label="Code Postal" label-for="zipCode-input">
                <b-form-input
                  v-model="devisToUpdate.zipCode"
                  id="zipCode-input"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="8" lg="5">
              <b-form-group label="Ville" label-for="ville-input">
                <b-form-input
                  v-model="devisToUpdate.town"
                  id="ville-input"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Surface (m²)" label-for="Surface-input">
                <b-form-input
                  id="Surface-input"
                  v-model="devisToUpdate.surface"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-group label="Précarité">
                <b-form-radio-group
                  v-model="devisToUpdate.precariousness"
                  :options="precarite"
                  stacked
                ></b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Type de chauffage">
                <b-form-radio-group
                  v-model="devisToUpdate.type_chauffage"
                  :options="type_chauffage"
                  stacked
                ></b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Zone">
                <b-form-radio-group
                  v-model="devisToUpdate.zone"
                  :options="zone"
                  stacked
                ></b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group label="E-mail d'agent" label-for="email-agent-input">
            <b-form-input
              v-model="devisToUpdate.email_agent"
              id="email-agent-input"
            ></b-form-input>
            <strong class="type"
              >* si vous souhaitez utiliser un autre email à la place de l'email
              de votre compte pour recevoir les notifications de signature des
              documents, merci de renseigner ce champ.
            </strong>
          </b-form-group>

          <div class="message">
            <div v-if="loading" class="three-dots-loading loading">
              Chargement en cours
            </div>
            <div v-if="error" class="error">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>
          </div>
          <div class="form-actions">
            <b-button
              @click="hideModal('updateDevisModal')"
              variant="outline-secondary"
            >
              Fermer
            </b-button>
            <b-button variant="success" type="submit">
              <span> Enregistrer </span>
            </b-button>
          </div>
        </form>
      </b-modal>
      <!-- END update Devis Modal  -->
      <!-- Delete Devis Modal -->
      <b-modal
        scrollable
        no-close-on-backdrop
        ref="ModalDeleteDevis"
        id="ModalDeleteDevis"
        title="Supprimer un dossier"
        :hide-footer="true"
      >
        <p>
          Êtes-vous certain de vouloir supprimer le devis
          <strong class="name_delete">
            {{ devisToDelete ? devisToDelete.full_name : '' }}</strong
          >
          ?
        </p>
        <div class="message">
          <div v-if="loading" class="three-dots-loading loading">
            Chargement en cours
          </div>
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
        </div>
        <div class="form-actions">
          <b-button
            @click="hideModal('ModalDeleteDevis')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button @click="handleSubmitDeleteDevis" variant="success">
            Enregistrer
          </b-button>
        </div>
      </b-modal>
      <!-- END Delete Devis Modal -->
      <!-- confirmation signed Modal -->
      <b-modal
        scrollable
        no-close-on-backdrop
        ref="ConfirmeSigned"
        id="ConfirmeSigned"
        title="Signature électronique"
        :hide-footer="true"
      >
        <p
          v-if="
            devisToSigned &&
              devisToSigned.type === 'devis' &&
              devisToSigned.signed === 'done'
          "
        >
          Ce devis est déjà signé, voulez-vous réenvoyer l'email de signature?
          <br />
          <strong class="name_delete">NB :</strong> le document signé sera
          écrasé et remplacé par la nouvelle signature.
        </p>
        <p
          v-if="
            devisToSigned &&
              devisToSigned.type === 'bc' &&
              devisToSigned.signed_bc === 'done'
          "
        >
          Ce bon de commande est déjà signé, voulez-vous réenvoyer l'email de
          signature?
          <br />
          <strong class="name_delete">NB :</strong> le document signé sera
          écrasé et remplacé par la nouvelle signature.
        </p>
        <p
          v-if="
            devisToSigned &&
              devisToSigned.type === 'devis' &&
              (devisToSigned.signed === 'none' ||
                devisToSigned.signed === 'pending')
          "
        >
          Un email sera envoyé au client, avec le lien pour signer le devis.
          Voulez-vous continuer ?
        </p>
        <p
          v-if="
            devisToSigned &&
              devisToSigned.type === 'bc' &&
              (devisToSigned.signed_bc === 'none' ||
                devisToSigned.signed_bc === 'pending')
          "
        >
          Un email sera envoyé au client, avec le lien pour signer le bon de
          commande. Voulez-vous continuer ?
        </p>

        <div v-if="devisToSigned && devisToSigned.type === 'bc'">
          <b-form-group label="Paiement:" class="mt-2">
            <b-form-radio-group
              v-model="devisToSigned.paiment"
              :options="[
                { text: 'à crédit', value: 'credit' },
                { text: 'au comptant', value: 'comptant' }
              ]"
            ></b-form-radio-group>
          </b-form-group>
          <b-form-group
            label="Organisme de financement"
            label-for="preteur-input"
          >
            <b-form-select id="preteur-input" v-model="devisToSigned.preteur">
              <option
                :value="preteur.id"
                v-for="preteur in getDevisPreteurs"
                :key="preteur.id"
              >
                {{ preteur.name }}
              </option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="message">
          <div v-if="loading" class="three-dots-loading loading">
            Chargement en cours
          </div>
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
        </div>
        <div class="form-actions">
          <b-button
            @click="hideModal('ConfirmeSigned')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button @click="sign()" variant="success">
            Enregistrer
          </b-button>
        </div>
      </b-modal>
      <!-- END confirmation signed Modal -->

      <!-- Download Modal -->
      <b-modal
        scrollable
        no-close-on-backdrop
        ref="DownloadFile"
        id="DownloadFile"
        :title="
          'Téléchargement de ' +
            (fileToDownload && fileToDownload.type === 'bc'
              ? 'bon de commande'
              : fileToDownload && fileToDownload.type === 'cc'
              ? 'document (cadre de contribution)'
              : 'devis')
        "
        :hide-footer="true"
      >
        <p>
          Téléchargement de
          {{
            fileToDownload && fileToDownload.type === 'bc'
              ? 'bon de commande'
              : fileToDownload && fileToDownload.type === 'cc'
              ? ' document (cadre de contribution)'
              : 'devis'
          }}
          <strong v-if="fileToDownload && fileToDownload.type !== 'cc'">
            {{
              fileToDownload &&
              fileToDownload.type === 'bc' &&
              fileToDownload.signed_bc === 'done'
                ? 'signé'
                : fileToDownload &&
                  fileToDownload.type === 'devis' &&
                  fileToDownload.signed === 'done'
                ? 'signé'
                : 'non signé'
            }}
          </strong>
        </p>
        <div v-if="fileToDownload && fileToDownload.type !== 'cc'">
          Code:
          {{
            fileToDownload && fileToDownload.type === 'bc'
              ? fileToDownload.code_bc
              : ''
          }}
          {{
            fileToDownload && fileToDownload.type === 'devis'
              ? fileToDownload.code
              : ''
          }}
        </div>
        <div>
          Client:
          {{ fileToDownload ? fileToDownload.full_name : '' }}
        </div>
        <div
          v-if="
            fileToDownload &&
              fileToDownload.type === 'bc' &&
              fileToDownload.signed !== 'done'
          "
        >
          <b-form-group label="Paiement:" class="mt-2">
            <b-form-radio-group
              v-model="fileToDownload.paiment"
              :options="[
                { text: 'à crédit', value: 'credit' },
                { text: 'au comptant', value: 'comptant' }
              ]"
            ></b-form-radio-group>
          </b-form-group>
          <b-form-group
            label="Organisme de financement"
            label-for="preteur-input"
          >
            <b-form-select id="preteur-input" v-model="fileToDownload.preteur">
              <option
                :value="preteur.id"
                v-for="preteur in getDevisPreteurs"
                :key="preteur.id"
              >
                {{ preteur.name }}
              </option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="message">
          <div v-if="loading" class="three-dots-loading loading">
            Chargement en cours
          </div>
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
        </div>
        <div class="form-actions">
          <b-button
            @click="hideModal('DownloadFile')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button @click="download(fileToDownload)" variant="success">
            Télécharger
          </b-button>
        </div>
      </b-modal>
      <!-- END Download Modal -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'devis',
  data() {
    return {
      devisToAdd: {
        first_name: null,
        last_name: null,
        address: null,
        town: null,
        type_chauffage: '',
        zone: '',
        precariousness: '',
        surface: null,
        email_client: null,
        email_agent: null,
        phone: '',
        zipCode: null,
        surface_rail: '',
        surface_couronnement: '',
        surface_goutte_eau: '',
        surface_angle: '',
        surface_rebords: ''
      },
      bindProps: {
        mode: 'international',
        defaultCountry: 'FR',
        placeholder: 'Entrer un numéro de téléphone',
        required: true
      },
      precarite: [
        { text: 'Grande', value: 'high' },
        { text: 'Simple', value: 'low' }
      ],
      type_chauffage: [
        { text: 'Combustible', value: 'combustible' },
        { text: 'Electrique', value: 'electrique' }
      ],
      zone: [
        { text: 'H1', value: 'h1' },
        { text: 'H2', value: 'h2' }
      ],
      full_name_code: '',
      sortDirection: 'asc',
      page: 1,
      per_page: 10,
      sort_by: 'first_name',
      sort_by_desc: 'first_name',
      devisToUpdate: null,
      devisToDelete: null,
      devisToSigned: null,
      fileToDownload: null,
      loading: false,
      initLoading: true,
      error: null,
      message_error: null,
      validatePhone: true
    }
  },
  methods: {
    ...mapActions([
      'addDevisIti',
      'getListDevisIti',
      'updateDevisIti',
      'deleteDevisIti',
      'downloadPDFIti',
      'signDevisIti',
      'downloadCCIti',
      'fetchPreteurs'
    ]),
    hideModal(name) {
      this.$refs[name].hide()
      this.resetModal()
    },
    resetModal() {
      this.devisToAdd = {
        first_name: null,
        last_name: null,
        address: null,
        town: null,
        type_chauffage: '',
        zone: '',
        precariousness: '',
        surface: null,
        email_client: null,
        email_agent: null,
        phone: '',
        bindProps: {
          mode: 'international',
          defaultCountry: 'FR',
          placeholder: 'Entrer un numéro de téléphone',
          required: true
        },
        zipCode: null,
        surface_rail: '',
        surface_couronnement: '',
        surface_goutte_eau: '',
        surface_angle: '',
        surface_rebords: ''
      }
      this.precarite = [
        { text: 'Grande', value: 'high' },
        { text: 'Simple', value: 'low' }
      ]
      this.type_chauffage = [
        { text: 'Combustible', value: 'combustible' },
        { text: 'Electrique', value: 'electrique' }
      ]
      this.zone = [
        { text: 'H1', value: 'h1' },
        { text: 'H2', value: 'h2' }
      ]
      this.devisToUpdate = null
      this.devisToDelete = null
      this.devisToSigned = null
      this.fileToDownload = null
      this.loading = false
      this.error = null
      this.message_error = null
    },
    handleClickUpdate(data_item) {
      this.devisToUpdate = { ...data_item }
      this.$refs['updateDevisModal'].show()
    },
    hundleDeleteClick(data_item) {
      this.devisToDelete = data_item
      this.$refs['ModalDeleteDevis'].show()
    },
    hundleConfirmeSignedClick(data_item, type) {
      this.devisToSigned = {
        ...data_item,
        type: type,
        paiment: 'credit',
        preteur:
          this.getDevisPreteurs && this.getDevisPreteurs[0]
            ? this.getDevisPreteurs[0].id
            : null
      }
      this.$refs['ConfirmeSigned'].show()
    },
    hundleDownloadClick(item, type) {
      this.fileToDownload = {
        ...item,
        type: type,
        paiment: 'credit',
        preteur:
          this.getDevisPreteurs && this.getDevisPreteurs[0]
            ? this.getDevisPreteurs[0].id
            : null
      }
      this.$refs['DownloadFile'].show()
    },
    async handleSubmit() {
      this.loading = true
      this.error = null
      const validate = this.validateDevis(this.devisToAdd)
      if (validate.status === 'error') {
        this.error = validate.errors
        this.loading = false
        return
      }
      const response = await this.addDevisIti(this.devisToAdd)
      if (response.success) {
        this.loading = false
        this.hideModal('addDevisModal')
        if (this.sortDirection === 'asc') {
          this.getListDevisIti({
            page: this.page,
            per_page: this.per_page,
            sort_by: this.sort_by,
            full_name_code: this.full_name_code
          })
        } else {
          this.getListDevisIti({
            page: this.page,
            per_page: this.per_page,
            sort_by_desc: this.sort_by_desc,
            full_name_code: this.full_name_code
          })
        }
      } else {
        this.loading = false
        this.error = response.error
      }
    },
    async handleSubmitUpdateDevis() {
      this.loading = true
      this.error = null
      const validate = this.validateDevis(this.devisToUpdate)
      if (validate.status === 'error') {
        this.error = validate.errors
        this.loading = false
        return
      }
      const response = await this.updateDevisIti(this.devisToUpdate)
      if (response.success) {
        this.loading = false
        this.hideModal('updateDevisModal')
        if (this.sortDirection === 'asc') {
          this.getListDevisIti({
            page: this.page,
            per_page: this.per_page,
            sort_by: this.sort_by,
            full_name_code: this.full_name_code
          })
        } else {
          this.getListDevisIti({
            page: this.page,
            per_page: this.per_page,
            sort_by_desc: this.sort_by_desc,
            full_name_code: this.full_name_code
          })
        }
      } else {
        this.loading = false
        this.error = response.error
      }
    },

    validateDevis(devis) {
      const validate = {
        status: 'success',
        errors: []
      }
      if (!devis.precariousness) {
        validate.status = 'error'
        validate.errors.push('Précarité est obligatoire')
      }
      if (!devis.type_chauffage) {
        validate.status = 'error'
        validate.errors.push('Type de chauffage est obligatoire')
      }
      if (!devis.zone) {
        validate.status = 'error'
        validate.errors.push('Zone est obligatoire')
      }
      if (!this.validatePhone) {
        validate.status = 'error'
        validate.errors.push('Le numéro de téléphone est invalide')
      }
      return validate
    },
    async handleSubmitDeleteDevis() {
      this.loading = true
      this.error = null
      const response = await this.deleteDevisIti(this.devisToDelete.id)
      if (response.success) {
        this.loading = false
        this.hideModal('ModalDeleteDevis')
        if (this.sortDirection === 'asc') {
          this.getListDevisIti({
            page: this.page,
            per_page: this.per_page,
            sort_by: this.sort_by,
            full_name_code: this.full_name_code
          })
        } else {
          this.getListDevisIti({
            page: this.page,
            per_page: this.per_page,
            sort_by_desc: this.sort_by_desc,
            full_name_code: this.full_name_code
          })
        }
      } else {
        this.loading = false
        this.error = response.error
      }
    },
    async sign() {
      this.loading = true
      this.error = null
      const response = await this.signDevisIti(this.devisToSigned)
      if (response.success) {
        this.loading = false
        this.hideModal('ConfirmeSigned')
        this.$bvToast.toast("L'email de la signature électronique est envoyé", {
          title: 'Succès',
          autoHideDelay: 2000,
          toaster: 'b-toaster-bottom-right',
          variant: 'success',
          solid: true
        })
      } else {
        this.loading = false
        this.error = response.error
        this.$bvToast.toast(
          "Une erreur s'est produite lors de l'envoi de l'email de la signature électronique",
          {
            title: 'Erreur',
            autoHideDelay: 2000,
            toaster: 'b-toaster-bottom-right',
            variant: 'danger',
            solid: true
          }
        )
      }
    },
    async download(data) {
      this.loading = true
      this.error = null

      let response = null
      if (data.type == 'cc') {
        response = await this.downloadCCIti(data)
      } else {
        response = await this.downloadPDFIti(data)
      }

      if (response && response.success) {
        this.loading = false
        this.hideModal('DownloadFile')
        this.$bvToast.toast('Téléchargement effectué avec succès', {
          title: 'Succès',
          autoHideDelay: 2000,
          toaster: 'b-toaster-bottom-right',
          variant: 'success',
          solid: true
        })
      } else {
        this.loading = false
        this.error = response.error
        this.$bvToast.toast(
          "Une erreur s'est produite lors de téléchargement de document",
          {
            title: 'Erreur',
            autoHideDelay: 2000,
            toaster: 'b-toaster-bottom-right',
            variant: 'danger',
            solid: true
          }
        )
      }
    },
    pagination(paginate) {
      this.page = paginate
      if (this.sortDirection === 'asc') {
        this.getListDevisIti({
          page: this.page,
          per_page: this.per_page,
          sort_by: this.sort_by,
          full_name_code: this.full_name_code
        })
      } else {
        this.getListDevisIti({
          page: this.page,
          per_page: this.per_page,
          sort_by_desc: this.sort_by_desc,
          full_name_code: this.full_name_code
        })
      }
    },
    filter() {
      this.page = 1
      if (this.sortDirection === 'asc') {
        this.getListDevisIti({
          page: this.page,
          per_page: this.per_page,
          sort_by: this.sort_by,
          full_name_code: this.full_name_code
        })
      } else {
        this.getListDevisIti({
          page: this.page,
          per_page: this.per_page,
          sort_by_desc: this.sort_by_desc,
          full_name_code: this.full_name_code
        })
      }
    },
    sort(fieldName) {
      if (fieldName === this.sort_by) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
      }
      this.currentSort = fieldName
      if (this.sortDirection === 'asc') {
        this.getListDevisIti({
          page: this.page,
          per_page: this.per_page,
          sort_by: this.sort_by,
          full_name_code: this.full_name_code
        })
      } else {
        this.getListDevisIti({
          page: this.page,
          per_page: this.per_page,
          sort_by_desc: this.sort_by_desc,
          full_name_code: this.full_name_code
        })
      }
    },
    phoneInternational({ number, isValid }) {
      if (this.devisToAdd) {
        if (number.national && number.national.length >= 2) {
          this.devisToAdd.phone = number.international
          if (!isValid) {
            this.message_error = 'Le numéro de téléphone est invalide'
            this.validatePhone = false
          } else {
            this.message_error = ''
            this.validatePhone = true
          }
        }
      }
      if (this.devisToUpdate) {
        if (number.national && number.national.length >= 2)
          this.devisToUpdate.phone = number.international
        if (!isValid) {
          this.message_error = 'Le numéro de téléphone est invalide'
          this.validatePhone = false
        } else {
          this.message_error = ''
          this.validatePhone = true
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'devisIti',
      'getDevisLoadingIti',
      'devisCountIti',
      'currentUser',
      'getDevisPreteurs'
    ])
  },
  async mounted() {
    await this.getListDevisIti({
      page: this.page,
      per_page: this.per_page,
      sort_by: this.sort_by
    })
    this.fetchPreteurs()
    this.initLoading = false
  }
}
</script>
<style lang="scss">
.content-tab-devis {
  table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    border: 0;
    border-top: 9px solid #dee2e6;
    border-bottom: 1px solid #b9babb;
    margin-top: -8px;
    font-size: 13px;
    text-align: center;

    tr {
      &:hover {
        th {
          background: #dee2e6;
        }
      }
    }
  }
  .table.b-table.table-sm > thead > tr > [aria-sort].b-table-sort-icon-left,
  .table.b-table.table-sm > tfoot > tr > [aria-sort].b-table-sort-icon-left {
    background-position: left calc(0.3rem / 2) center;
    padding-left: calc(1.3rem + 0.65em);
  }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 1px solid #dee2e6;
  }
  .table-sm th,
  .table-sm td {
    padding: 2px;
    border: 1px solid #dee2e6;
    vertical-align: middle;
  }
  .table-sm td {
    padding-bottom: 0px;
  }
  .b-table-sticky-header {
    overflow-y: auto;
    max-height: 530px;
  }
  .table.b-table > thead > tr > .table-b-table-default,
  .table.b-table > tbody > tr > .table-b-table-default,
  .table.b-table > tfoot > tr > .table-b-table-default {
    font-size: 12px;
    color: #212529;
    background-color: #f8f8f8;
  }
  .actions-tab {
    display: flex;
    text-align: center;
  }
  .button_delete {
    margin-left: 15px;
  }
  .check {
    color: #28a745;
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 0rem;
  }
  .nom_client {
    text-transform: capitalize;
  }
  .b-table-sticky-header > .table.b-table > thead > tr > th {
    position: -webkit-sticky;
    position: sticky;
    top: -4px;
    z-index: 2;
    vertical-align: middle;
  }
}
</style>
<style scoped lang="scss">
.page-content {
  margin: 60px;
  padding: 15px;
  min-height: 100%;
  background-color: #f8f8f8;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .content-devis {
    .search {
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      .input-search {
        border: 1px solid #dadada;
        outline: none;
        font-size: 16px;
        height: 40px;
        background: #fff;
        padding-left: 10px;
      }
      .searchIcon {
        padding: 9px;
        border: 1px solid #d6d8db;
        font-size: 16px;
        background: #d6d8da17;
      }
      .sortloading {
        margin-left: 60px;
      }
    }
    .content-tab-devis {
      .btn {
        padding: 1px 4px;
      }
      .sans_border {
        position: sticky;
        top: 14px;
        z-index: 10;
      }
      .svg-inline--fa.fa-w-10 {
        margin-right: 10px;
        width: 0.625em;
        font-size: 14px;
      }
      .font,
      .font-name {
        font-weight: normal;
        &.font-name {
          text-transform: capitalize;
        }
      }

      .pagination {
        display: flex;
        justify-content: space-between;
        justify-items: center;
        width: 100%;
        .loading {
          width: 50%;
        }
      }
    }
  }
}
</style>
<style lang="scss">
#addDevisModal,
#updateDevisModal {
  .groupe_input {
    display: flex;
    .lastname,
    .zipCode {
      margin-left: 10px;
    }
  }
  .type {
    font-weight: normal;
    font-size: 12px;
    color: #317ae2;
    padding-left: 26px;
  }
  .message-phone-error {
    font-weight: normal;
    font-size: 12px;
    color: #ff5722;
    padding-left: 4px;
  }
  .buttons {
    .btn-group,
    .btn-group-vertical {
      position: relative;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      vertical-align: middle;
      padding: 10px;
      margin-left: 19px;
    }
    button {
      font-weight: normal;
      font-size: 13px;
    }
  }
  .form-actions {
    padding-top: 10px;
  }
}
#ModalDeleteDevis {
  .name_delete {
    text-transform: capitalize;
  }
}
</style>
